import { withPrefix } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import slugify from 'slugify'
import { v4 as uuidv4 } from 'uuid'
import Button from '../components/Button'
import StyledHomeMain from '../styles/StyledHomeMain'

const HomeMain = ({ signedIn }) => {
  const data = [
    {
      alt: 'mud snails underwater near a clam shell',
      caption: 'Inside the World of the Mud Snail',
      label: 'LAUNCH',
      src: withPrefix('/dswmedia/inside-the-world-of-the-mud-snail.jpg'),
      url: './modules/mud-snails/',
    },
    {
      alt: 'an estuary on a sunny day',
      caption: 'Microscopic Life in the Estuary',
      label: 'LAUNCH',
      src: withPrefix('/dswmedia/microscope-life-in-the-estuary.jpg'),
      url:
        './modules/microscopic-life-in-the-estuary/',
    },
    // {
    //   alt: 'a group of people working to clean up trash at a beach',
    //   caption: 'Conservation Station',
    //   label: 'LAUNCH',
    //   src: withPrefix('/dswmedia/conservation-station.jpg'),
    //   url: 'https://k12campus.net/whitney/modules/conservation-station/',
    // },
    // {
    //   alt: 'a small sea turtle crawling to the ocean',
    //   caption: 'Journey of the Sea Turtle',
    //   disabled: true,
    //   label: 'COMING SOON',
    //   src: withPrefix('/dswmedia/journey-of-the-sea-turtle.jpg'),
    //   url: '',
    // },
  ]

  const createMarkup = s => {
    return {
      __html: s,
    }
  }

  const handleLaunch = url => {
    window.open(url, '_blank')
  }

  return (
    <StyledHomeMain aria-labelledby="main-heading">
      <h2 id="main-heading">
        Welcome to Whitney Lab Online where you can be a scientist for a day!
      </h2>
      <p className="main-intro">
        The University of Florida Whitney Laboratory for Marine Bioscience
        provides a K-12 educational outreach program to engage students in
        lifelong learning and support STEM education in our schools.
      </p>
      <div className="main-course-listing">
        {data.map(({ alt, caption, disabled, label, src, url }) => (
          <div key={uuidv4()}>
            <figure>
              <img src={src} alt={alt} />
              <figcaption>
                <span dangerouslySetInnerHTML={createMarkup(caption)} />
                <Button
                  disabled={!signedIn ? true : disabled}
                  label={
                    <>
                      {!signedIn || disabled ? (
                        <svg>
                          <use xlinkHref="#icon-lock-closed" />
                        </svg>
                      ) : (
                        ''
                      )}
                      {label}
                    </>
                  }
                  onClick={() => handleLaunch(url)}
                  name={slugify(caption)}
                  variant="primary"
                />
              </figcaption>
            </figure>
          </div>
        ))}
      </div>
    </StyledHomeMain>
  )
}

HomeMain.propTypes = {
  signedIn: PropTypes.bool,
}

export default HomeMain
